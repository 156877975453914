import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const AwardScene = (useMarkers) => {
  const award = document.getElementById('award');

  gsap.fromTo(
    award,
    { opacity: 0 },
    {
      opacity: 1,
      scrollTrigger: {
        trigger: award,
        scrub: true,
        markers: useMarkers,
        start: () => 'top center',
        end: () => '+=100 center',
      },
    }
  );
};

export default AwardScene;
