import qs from 'query-string';

class JobsApplyController {
  async #fetchJob(jobId) {
    try {
      const response = await fetch(
        `https://boards-api.greenhouse.io/v1/boards/radishfiction/jobs/${jobId}`
      );

      if (!response.ok) {
        throw new Error();
      }

      return response.json();
    } catch (e) {
      console.error(e);
    }
  }

  async init() {
    try {
      const jobObject = qs.parse(window.location.search);
      const jobId = jobObject.gh_jid;

      const job = await this.#fetchJob(jobId);

      const department = job.departments[0];
      const departmentLink = document.getElementById('departmentLink');

      departmentLink.href = `/jobs/?department_id=${department.id}#openPositionsWrap`;
      departmentLink.innerText = department.name;
    } catch (e) {
      console.error('Failed to parse.');
    }
  }
}

export default JobsApplyController;
