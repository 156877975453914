import qs from 'query-string';

import JobTemplate from '../_includes/job-item.hbs';
import DepartmentTemplate from '../_includes/department-item.hbs';
import OfficeTemplate from '../_includes/office-item.hbs';

class JobsController {
  #jobs = [];

  #initializeJobs(data) {
    data.forEach(
      ({
        title,
        departments,
        location: loc,
        offices,
        absolute_url: url,
        metadata,
      }) => {
        const departmentName = departments ? departments[0].name : '';
        const departmentId = departments ? departments[0].id : '';
        const locationName = loc ? loc.name : '';
        const officeId = offices ? offices[0].id : '';
        const employmentType = metadata ? metadata[0].value : '';

        this.#jobs.push({
          title,
          departmentName,
          departmentId,
          locationName,
          officeId,
          url,
          employmentType,
        });
      }
    );

    Object.freeze(this.#jobs);
    document.getElementById('jobList').innerHTML = JobTemplate({
      jobs: this.#jobs,
    });

    if (this.#jobs.length >= 11) {
      document
        .getElementById('openPositionsWrap')
        .classList.add('jobs__content--open-positions--show-partial');
    }
  }

  #initializeDepartments(data) {
    const departments = data
      .filter(({ id }) => id !== 0)
      .map(({ id, name }) => ({
        id,
        name,
      }));

    document.getElementById('departmentList').innerHTML += DepartmentTemplate({
      departments,
    });
  }

  #initializeOffices(data) {
    const offices = data
      .filter(({ id }) => id !== 0)
      .map(({ id, name }) => ({
        id,
        name,
      }));

    document.getElementById('officeList').innerHTML += OfficeTemplate({
      offices,
    });
  }

  async #fetchJobs() {
    try {
      const [jobResponse, depResponse, officeResponse] = await Promise.all([
        fetch(
          'https://boards-api.greenhouse.io/v1/boards/radishfiction/jobs?content=true'
        ),
        fetch(
          'https://boards-api.greenhouse.io/v1/boards/radishfiction/departments'
        ),
        fetch(
          'https://boards-api.greenhouse.io/v1/boards/radishfiction/offices'
        ),
      ]);

      if (!(jobResponse.ok && depResponse.ok && officeResponse.ok)) {
        throw new Error();
      }

      const { jobs } = await jobResponse.json();
      const { departments } = await depResponse.json();
      const { offices } = await officeResponse.json();

      return [jobs, departments, offices];
    } catch (e) {
      console.error(e);
    }
  }

  #filterJobs(filterDepartmentId = 'all', filterOfficeId = 'all') {
    const filteredJobs = this.#jobs.filter((job) => {
      const isDepartmentMatched =
        filterDepartmentId === 'all' || filterDepartmentId == job.departmentId;

      const isOfficesMatched =
        filterOfficeId === 'all' || filterOfficeId == job.officeId;

      return isDepartmentMatched && isOfficesMatched;
    });

    document.getElementById('jobList').innerHTML = JobTemplate({
      jobs: filteredJobs,
    });

    if (filteredJobs.length === 0) {
      document.getElementById('noJob').classList.remove('hide');
      document.getElementById('jobTable').classList.add('hide');
    } else {
      document.getElementById('noJob').classList.add('hide');
      document.getElementById('jobTable').classList.remove('hide');
    }
  }

  #handleDepartmentChange(event) {
    this.#filterJobs(
      event.target.value,
      document.getElementById('officeList').value
    );
  }

  #handleOfficeChange(event) {
    this.#filterJobs(
      document.getElementById('departmentList').value,
      event.target.value
    );
  }

  #handleShowAllButtonClick() {
    document
      .getElementById('openPositionsWrap')
      .classList.remove('jobs__content--open-positions--show-partial');
  }

  async init() {
    document
      .getElementById('departmentList')
      .addEventListener('change', this.#handleDepartmentChange.bind(this));

    document
      .getElementById('officeList')
      .addEventListener('change', this.#handleOfficeChange.bind(this));

    document
      .getElementById('viewAllPositionsButton')
      .addEventListener('click', this.#handleShowAllButtonClick.bind(this));

    const [jobs, departments, offices] = await this.#fetchJobs();

    this.#initializeJobs(jobs);
    this.#initializeDepartments(departments);
    this.#initializeOffices(offices);

    const jobObject = qs.parse(window.location.search);
    const departmentId = jobObject.department_id;

    if (departmentId) {
      this.#filterJobs(departmentId);
      document.getElementById('departmentList').value = departmentId;
    }
  }
}

export default JobsController;
