import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const AuthorsScene = (useMarkers) => {
  const authorsText = document.getElementById('authorsText');
  const authorsBackground = document.getElementById('authorsBackground');

  gsap.fromTo(
    authorsText,
    { opacity: 0, transform: 'translate3d(0, 40px, 0)' },
    {
      opacity: 1,
      transform: 'translate3d(0, 0, 0)',
      scrollTrigger: {
        trigger: authorsText,
        scrub: true,
        markers: useMarkers,
        start: () => 'top center',
        end: () => '+=100 center',
      },
    }
  );

  gsap.fromTo(
    authorsBackground,
    { opacity: 0 },
    {
      opacity: 1,
      scrollTrigger: {
        trigger: authorsText,
        scrub: true,
        markers: useMarkers,
        start: () => '+=100 center',
        end: () => 'top+=200 center',
      },
    }
  );
};

export default AuthorsScene;
