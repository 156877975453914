class ProfileModalController {
  showProfileModal(biography, name, portrait, role) {
    document.body.classList.add(
      'body--fixed-backdrop-on-tablet',
      'body--profile-modal'
    );

    const profileModalPortrait = document.getElementById(
      'profileModalPortrait'
    );
    const profileModalRole = document.getElementById('profileModalRole');
    const profileModalName = document.getElementById('profileModalName');
    const profileModalBiography = document.getElementById(
      'profileModalBiography'
    );

    if (profileModalPortrait) {
      profileModalPortrait.src = portrait;
    }

    if (profileModalRole) {
      profileModalRole.innerText = role;
    }

    if (profileModalName) {
      profileModalName.innerText = name;
    }

    if (profileModalBiography) {
      profileModalBiography.innerHTML = biography;
    }
  }

  #handleOpenClick(event) {
    const profileModalTrigger = event.target.closest('.use-profile-modal');

    if (profileModalTrigger) {
      const { biography, name, portrait, role } = profileModalTrigger.dataset;

      this.showProfileModal(biography, name, portrait, role);
    }
  }

  #handleCloseClick(event) {
    if (event) {
      event.preventDefault();
    }

    document.body.classList.remove(
      'body--fixed-backdrop-on-tablet',
      'body--profile-modal'
    );
  }

  #handleEscPress(e) {
    if (e.key === 'Escape') {
      this.#handleCloseClick();
    }
  }

  init() {
    document.body.addEventListener('click', this.#handleOpenClick.bind(this));
    const buttonCloseProfileModal = document.getElementById(
      'buttonCloseProfileModal'
    );

    if (buttonCloseProfileModal) {
      buttonCloseProfileModal.addEventListener(
        'click',
        this.#handleCloseClick.bind(this)
      );
    }

    document.addEventListener('keydown', this.#handleEscPress.bind(this));
  }
}

export default ProfileModalController;
