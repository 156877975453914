import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const ReviewScene = (useMarkers) => {
  const section = document.getElementById('sectionFeatured');
  const reviewItemsContainer = document.getElementById('reviewItems');
  const offset = 80;

  const reviewItemTimeline = gsap.timeline({
    scrollTrigger: {
      trigger: reviewItemsContainer,
      start: `-=${offset} center`,
      end: () => 'center center',
      markers: useMarkers,
      scrub: true,
    },
  });

  Array.prototype.forEach.call(reviewItemsContainer.children, (el) => {
    reviewItemTimeline.fromTo(
      el,
      { transform: 'scale(0)' },
      { transform: 'scale(1.0)' },
      '-=0.25'
    );
  });

  gsap.fromTo(
    section,
    { opacity: 1 },
    {
      opacity: 0,
      scrollTrigger: {
        markers: useMarkers,
        scrub: true,
        trigger: reviewItemsContainer,
        start: `-=${offset} center`,
        end: () => 'center center',
      },
    }
  );
};

export default ReviewScene;
