import { gsap } from 'gsap';

const DurationSecondEachAnimations = 2;
const DelaySecondEachAnimations = 0.2;
const NumberOfScenes = 3;

class FeaturedSectionAnimationController {
  #homeBackgrounds = undefined;

  #homeFeaturedStories = undefined;

  #backgroundTimeline = undefined;

  #featuredImageTimeline = undefined;

  #featuredTextTimeline = undefined;

  #imagesToFetch = undefined;

  #fetchImage(imgElement) {
    return new Promise((resolve, reject) => {
      const { lazySrc } = imgElement.dataset;

      if (!lazySrc) {
        reject();
        return;
      }

      const callback = () => {
        imgElement.removeEventListener('load', callback);
        resolve(lazySrc);
      };

      imgElement.addEventListener('load', callback);
      imgElement.src = lazySrc;
    });
  }

  init() {
    /*
     * get DOM Elements
     */
    this.#homeBackgrounds = document.getElementById('homeBackgrounds');
    this.#homeFeaturedStories = document.getElementById('homeFeaturedStories');

    const backgroundImages = Array.from(
      this.#homeBackgrounds.getElementsByClassName(
        'home__background-image--hidden'
      )
    );

    const storyImages = Array.from(
      this.#homeFeaturedStories.getElementsByClassName(
        'home__featured-cover-image--hidden'
      )
    );
    const textImages = Array.from(
      this.#homeFeaturedStories.getElementsByClassName(
        'home__featured-text-image--hidden'
      )
    );

    /*
     * set up promise objects to load all images from scenes
     */
    this.#imagesToFetch = [...backgroundImages, ...storyImages, ...textImages];

    const timelineOption = { repeat: -1 };

    this.#backgroundTimeline = gsap.timeline(timelineOption);
    this.#featuredImageTimeline = gsap.timeline(timelineOption);
    this.#featuredTextTimeline = gsap.timeline(timelineOption);
  }

  async start() {
    try {
      /*
       * fetch images
       */
      await Promise.all(
        this.#imagesToFetch.map((image) => this.#fetchImage(image))
      );

      /*
       * set up and start animations if all images have been loaded successfully.
       */
      for (let i = 1; i <= NumberOfScenes; i += 1) {
        const isOdd = i % 2 > 0;
        this.#backgroundTimeline.fromTo(
          this.#homeBackgrounds,
          {
            transform: isOdd ? 'scale(1.03)' : 'scale(1)',
          },
          {
            transform: isOdd ? 'scale(1)' : 'scale(1.03)',
            delay: DelaySecondEachAnimations,
            duration: DurationSecondEachAnimations,
            onComplete: () => {
              this.#homeBackgrounds.classList.remove(
                `home__background--show-child-${i}`
              );
              this.#homeBackgrounds.classList.add(
                `home__background--show-child-${(i % NumberOfScenes) + 1}`
              );
            },
          }
        );

        this.#featuredImageTimeline.fromTo(
          this.#homeFeaturedStories,
          {
            transform: isOdd ? 'scale(0.97)' : 'scale(1)',
          },
          {
            transform: isOdd ? 'scale(1)' : 'scale(0.97)',
            delay: DelaySecondEachAnimations,
            duration: DurationSecondEachAnimations,
            onComplete: () => {
              this.#homeFeaturedStories.classList.remove(
                `home__featured-images--show-child-${i}`
              );
              this.#homeFeaturedStories.classList.add(
                `home__featured-images--show-child-${(i % NumberOfScenes) + 1}`
              );
            },
          }
        );
      }

      this.#featuredTextTimeline.fromTo(
        this.#homeFeaturedStories.getElementsByClassName(
          'home__featured-text-image'
        ),
        {
          opacity: 0,
        },
        {
          opacity: 1,
          delay: DelaySecondEachAnimations,
          duration: DurationSecondEachAnimations,
        }
      );
    } catch (e) {
      console.error(e);
    }
  }

  toggle() {
    if (this.#backgroundTimeline.paused()) {
      this.resume();
    } else {
      this.pause();
    }
  }

  resume() {
    this.#backgroundTimeline.resume();
    this.#featuredImageTimeline.resume();
    this.#featuredTextTimeline.resume();
  }

  pause() {
    this.#backgroundTimeline.pause();
    this.#featuredImageTimeline.pause();
    this.#featuredTextTimeline.pause();
  }
}

export default FeaturedSectionAnimationController;
