import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const CommunityScene = (useMarkers) => {
  const reading = document.getElementById('reading');
  const community = document.getElementById('community');
  const communityText = document.getElementById('communityText');

  const radishLiveImage = document.getElementById('communityRadishLiveImage');
  const commentImage = document.getElementById('communityCommentImage');

  const communityBackground = document.getElementById('communityBackground');
  const communityOval = document.getElementById('communityOval');

  const wrapperScrollTrigger = {
    trigger: reading,
    scrub: true,
    markers: useMarkers
      ? { startColor: 'green', endColor: 'yellow', fontSize: '12px' }
      : false,
    start: () => 'bottom-=10% center',
    end: () => 'bottom center',
  };

  const phoneImageScrollTrigger = {
    trigger: communityText,
    scrub: true,
    markers: useMarkers,
    start: () => 'top center',
    end: () => 'bottom center',
  };

  gsap.fromTo(
    communityText,
    { transform: 'translate3d(0, 60px, 0)' },
    {
      transform: 'translate3d(0, 0, 0)',
      scrollTrigger: wrapperScrollTrigger,
    }
  );

  gsap.fromTo(
    community,
    { opacity: 0 },
    { opacity: 1, scrollTrigger: wrapperScrollTrigger }
  );

  gsap.to(radishLiveImage, {
    transform: 'translate3d(-35px, 0, 0)',
    scrollTrigger: phoneImageScrollTrigger,
  });

  gsap.to(commentImage, {
    transform: 'translate3d(35px, 0, 0)',
    scrollTrigger: phoneImageScrollTrigger,
  });

  gsap.fromTo(
    communityBackground,
    {
      opacity: 0,
    },
    {
      opacity: 1,
      scrollTrigger: {
        trigger: communityText,
        scrub: true,
        markers: useMarkers,
        start: () => 'center center',
        end: () => 'bottom+=100 center',
      },
    }
  );

  gsap.fromTo(
    communityOval,
    { rotation: 0 },
    {
      rotation: 1080,
      scrollTrigger: {
        trigger: communityText,
        scrub: true,
        markers: useMarkers,
        start: () => 'center center',
        end: () => 'center+=700 center',
      },
    }
  );
};

export default CommunityScene;
