class HelpCenterController {
  loadGroove() {
    /* prettier-ignore */
    /* eslint-disable */
    !(function (e, t) {
      if (!e.groove) {
        const i = function (e, t) { return Array.prototype.slice.call(e, t); }; var a = { widget: null, loadedWidgets: {}, classes: { Shim: null, Embeddable() { this._beforeLoadCallQueue = [], this.shim = null, this.finalized = !1; const e = function (e) { const t = i(arguments, 1); if (this.finalized) { if (!this[e]) throw new TypeError(`${e}() is not a valid widget method`); this[e].apply(this, t); } else this._beforeLoadCallQueue.push([e, t]); }; this.initializeShim = function () { a.classes.Shim && (this.shim = new a.classes.Shim(this)); }, this.exec = e, this.init = function () { e.apply(this, ['init'].concat(i(arguments, 0))), this.initializeShim(); }, this.onShimScriptLoad = this.initializeShim.bind(this), this.onload = void 0; } }, scriptLoader: { callbacks: {}, states: {}, load(e, i) { if (this.states[e] !== 'pending') { this.states[e] = 'pending'; const a = t.createElement('script'); a.id = e, a.type = 'text/javascript', a.async = !0, a.src = i; const s = this; a.addEventListener('load', (() => { s.states[e] = 'completed', (s.callbacks[e] || []).forEach(((e) => { e(); })); }), !1); const n = t.getElementsByTagName('script')[0]; n.parentNode.insertBefore(a, n); } }, addListener(e, t) { this.states[e] !== 'completed' ? (this.callbacks[e] || (this.callbacks[e] = []), this.callbacks[e].push(t)) : t(); } }, createEmbeddable() { const t = new a.classes.Embeddable(); return e.Proxy ? new Proxy(t, { get(e, t) { return e instanceof a.classes.Embeddable ? Object.prototype.hasOwnProperty.call(e, t) || t === 'onload' ? e[t] : function () { e.exec.apply(e, [t].concat(i(arguments, 0))); } : e[t]; } }) : t; }, createWidget() { const e = a.createEmbeddable(); return a.scriptLoader.load('groove-script', 'https://0f83bd46-258c-446e-aad7-b86445f8146a.widget.cluster.groovehq.com/api/loader'), a.scriptLoader.addListener('groove-iframe-shim-loader', e.onShimScriptLoad), e; } }; e.groove = a;
      }
    }(window, document));
    window.groove.widget = window.groove.createWidget();
    window.groove.widget.init('0f83bd46-258c-446e-aad7-b86445f8146a', {});
  }

  #handleHelpCenterClick(event) {
    const helpCenterTrigger = event.target.closest('.use-help-center');

    if (helpCenterTrigger) {
      event.preventDefault();
      if (!window.groove) {
        this.loadGroove();
      }

      window.groove.widget.open();
    }
  }

  init() {
    document.body.addEventListener(
      'click',
      this.#handleHelpCenterClick.bind(this)
    );
  }
}

export default HelpCenterController;
