const IntersectionThreshold = 70;

class IntersectionObserverController {
  intersectionObserver = undefined;

  init(options) {
    const handlers = options.map((option) => option.handler);

    this.intersectionObserver = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const { isIntersecting, target } = entry;
          if (isIntersecting) {
            handlers[target.dataset.intersectionObserverCallbackKey](target);
            this.intersectionObserver.unobserve(target);
          }
        });
      },
      {
        rootMargin: `${IntersectionThreshold}%`,
      }
    );

    const self = this;
    options.forEach((option, index) => {
      option.entries.forEach((entry) => {
        entry.dataset.intersectionObserverCallbackKey = index;
        self.intersectionObserver.observe(entry);
      });
    });
  }
}

export default IntersectionObserverController;
