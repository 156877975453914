class HomeStoryCardController {
  #currentCardIndex = undefined;

  #cardWrap = undefined;

  #authorProfileWrap = undefined;

  #awardSectionBackgroundShape = undefined;

  #initializeCards() {
    this.#currentCardIndex = 1;
    this.#cardWrap = document.getElementById('awardCovers');

    const cards = this.#getCards();

    document.getElementById('homeAuthorPageCurrent').innerText = String(
      this.#currentCardIndex
    ).padStart(2, '0');

    document.getElementById('homeAuthorPageMax').innerText = String(
      cards.length
    ).padStart(2, '0');

    for (let i = 0; i <= 3; i += 1) {
      if (!cards[i]) {
        return;
      }

      cards[i].classList.add(`home__award-cover-item--depth${i}`);
    }
  }

  #initializeProfiles() {
    this.#authorProfileWrap = document.getElementById('homeAuthors');
    this.#authorProfileWrap.firstElementChild.classList.add(
      'author-item--show'
    );
  }

  #initializeBackgroundShape() {
    this.#awardSectionBackgroundShape = document.getElementById(
      'awardSectionBackgroundShape'
    );

    this.#awardSectionBackgroundShape.classList.add(
      'home__background--award-1'
    );
  }

  #getCards() {
    return this.#cardWrap.children;
  }

  #getTopOfCard() {
    return this.#cardWrap.getElementsByClassName(
      'home__award-cover-item--depth0'
    )[0];
  }

  #getTopOfProfile() {
    return this.#authorProfileWrap.getElementsByClassName(
      'author-item--show'
    )[0];
  }

  #decreaseCurrentPageNumber() {
    if (this.#currentCardIndex <= 1) {
      return;
    }

    this.#awardSectionBackgroundShape.classList.remove(
      `home__background--award-${this.#currentCardIndex}`
    );

    this.#currentCardIndex -= 1;
    document.getElementById('homeAuthorPageCurrent').innerText = String(
      this.#currentCardIndex
    ).padStart(2, '0');

    this.#awardSectionBackgroundShape.classList.add(
      `home__background--award-${this.#currentCardIndex}`
    );
  }

  #increaseCurrentPageNumber() {
    this.#awardSectionBackgroundShape.classList.remove(
      `home__background--award-${this.#currentCardIndex}`
    );

    this.#currentCardIndex += 1;
    document.getElementById('homeAuthorPageCurrent').innerText = String(
      this.#currentCardIndex
    ).padStart(2, '0');

    this.#awardSectionBackgroundShape.classList.add(
      `home__background--award-${this.#currentCardIndex}`
    );
  }

  init() {
    this.#initializeCards();
    this.#initializeProfiles();
    this.#initializeBackgroundShape();
  }

  pop() {
    const currentAuthorProfile = this.#getTopOfProfile();

    if (currentAuthorProfile.previousElementSibling) {
      currentAuthorProfile.classList.remove('author-item--show');
      currentAuthorProfile.previousElementSibling.classList.add(
        'author-item--show'
      );
    }

    let currentTop = this.#getTopOfCard();

    if (!currentTop || !currentTop.previousElementSibling) {
      return;
    }

    currentTop.previousElementSibling.classList.remove(
      'home__award-cover-item--out'
    );
    currentTop.previousElementSibling.classList.add(
      'home__award-cover-item--depth0'
    );

    for (let i = 0; i < 3; i += 1) {
      if (currentTop) {
        currentTop.classList.remove('home__award-cover-item--out');
        currentTop.classList.remove(`home__award-cover-item--depth${i}`);
        currentTop.classList.add(`home__award-cover-item--depth${i + 1}`);
        currentTop = currentTop.nextElementSibling;
      }
    }

    while (currentTop) {
      currentTop.classList.remove('home__award-cover-item--out');
      currentTop.classList.remove('home__award-cover-item--depth3');
      currentTop = currentTop.nextElementSibling;
    }

    this.#decreaseCurrentPageNumber();
  }

  next() {
    const currentAuthorProfile = this.#getTopOfProfile();

    if (currentAuthorProfile.nextElementSibling) {
      currentAuthorProfile.classList.remove('author-item--show');
      currentAuthorProfile.nextElementSibling.classList.add(
        'author-item--show'
      );
    }

    let currentTop = this.#getTopOfCard();

    if (!currentTop || !currentTop.nextElementSibling) {
      return;
    }

    currentTop.classList.remove('home__award-cover-item--depth0');
    currentTop.classList.add('home__award-cover-item--out');

    for (let i = 0; i < 3; i += 1) {
      if (currentTop.nextElementSibling) {
        const next = currentTop.nextElementSibling;
        next.classList.remove(`home__award-cover-item--depth${i + 1}`);
        next.classList.add(`home__award-cover-item--depth${i}`);
        currentTop = next;
      }
    }

    if (currentTop.nextElementSibling) {
      currentTop.nextElementSibling.classList.add(
        'home__award-cover-item--depth3'
      );
    }

    this.#increaseCurrentPageNumber();
  }
}

export default HomeStoryCardController;
