import Bugsnag from '@bugsnag/js';
import SidebarController from './SidebarController';
import HomeController from './HomeController';
import JobsController from './JobsController';
import JobsApplyController from './JobsApplyController';
import NewsController from './NewsController';
import HelpCenterController from './HelpCenterController';
import ProfileModalController from './ProfileModalController';
import IntersectionObserverController from './IntersectionObserverController';

if (process.env.NODE_ENV === 'production') {
  /* eslint-disable */
  Bugsnag.start({ apiKey: BugSnagKey, appVersion: Version });
}

const App = () => {
  /* initialize each page controller */
  const sidebarController = new SidebarController();
  sidebarController.init();

  /* Groove HQ integration for a help center */
  const helpCenterController = new HelpCenterController();
  helpCenterController.init();

  const intersectionObserver = new IntersectionObserverController();
  intersectionObserver.init([
    {
      entries: Array.from(document.getElementsByTagName('img')),
      handler: (target) => {
        const dataSrc = target.dataset.src;
        if (dataSrc) {
          target.src = dataSrc;
        }
      },
    },
    {
      entries: Array.from(document.getElementsByClassName('lazy')),
      handler: (target) => {
        target.classList.remove('lazy');
      },
    },
    {
      entries: Array.from(document.getElementsByClassName('use-help-center')),
      handler: () => {
        helpCenterController.loadGroove();
      },
    },
  ]);

  if (document.getElementById('home')) {
    const homeController = new HomeController();
    homeController.init();
  }

  if (document.getElementById('jobs')) {
    const jobsController = new JobsController();
    jobsController.init();
  }

  if (document.getElementById('jobsApply')) {
    const jobsApplyController = new JobsApplyController();
    jobsApplyController.init();
  }

  if (document.getElementById('news')) {
    const newsController = new NewsController();
    newsController.init();
  }

  if (document.getElementById('about')) {
    const profileModalController = new ProfileModalController();
    profileModalController.init();
  }
};

App();
