class NewsController {
  showMoreEnglishNews = false;

  showMoreKoreanNews = false;

  handleViewMoreEnglishNews() {
    const list = document.getElementById('english-news-list');
    const button = document.getElementById('english-news-more');

    if (this.showMoreEnglishNews) {
      list.classList.add('news-regular__show-less');
      button.innerHTML = 'View more';
    } else {
      list.classList.remove('news-regular__show-less');
      button.innerHTML = 'View less';
    }

    this.showMoreEnglishNews = !this.showMoreEnglishNews;
  }

  handleViewMoreKoreanNews() {
    const list = document.getElementById('korean-news-list');
    const button = document.getElementById('korean-news-more');

    if (this.showMoreKoreanNews) {
      list.classList.add('news-regular__show-less');
      button.innerHTML = 'View more';
    } else {
      list.classList.remove('news-regular__show-less');
      button.innerHTML = 'View less';
    }

    this.showMoreKoreanNews = !this.showMoreKoreanNews;
  }

  init() {
    document
      .getElementById('english-news-more')
      .addEventListener('click', this.handleViewMoreEnglishNews);

    document
      .getElementById('korean-news-more')
      .addEventListener('click', this.handleViewMoreKoreanNews);
  }
}

export default NewsController;
