class SidebarController {
  #handleOpenSideMenu(event) {
    event.preventDefault();
    document.body.classList.add('body--fixed-backdrop-on-tablet');
    document.getElementById('header').classList.add('header--sidemenu-on');
  }

  #handleCloseSideMenu(event) {
    event.preventDefault();
    document.body.classList.remove('body--fixed-backdrop-on-tablet');
    document.getElementById('header').classList.remove('header--sidemenu-on');
  }

  init() {
    document
      .getElementById('buttonOpenSideMenu')
      .addEventListener('click', this.#handleOpenSideMenu);
    document
      .getElementById('buttonCloseSideMenu')
      .addEventListener('click', this.#handleCloseSideMenu);
  }
}

export default SidebarController;
