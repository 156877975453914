import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const NewEpisodeScene = (useMarkers) => {
  const newEpisodeTextWrap = document.getElementById('newEpisodeTextWrap');
  const newEpisodeText = document.getElementById('newEpisodeText');
  const newEpisodeBackground = document.getElementById('newEpisodeBackground');

  gsap.fromTo(
    newEpisodeText,
    { opacity: 0, transform: 'translate3d(0, 40px, 0)' },
    {
      opacity: 1,
      transform: 'translate3d(0, 0, 0)',
      scrollTrigger: {
        trigger: newEpisodeTextWrap,
        scrub: true,
        markers: useMarkers,
        start: () => 'top center',
        end: () => 'top+=80 center',
      },
    }
  );

  gsap.fromTo(
    newEpisodeBackground,
    { opacity: 0, transform: 'scale(0)' },
    {
      opacity: 1,
      transform: 'scale(1.0)',
      scrollTrigger: {
        trigger: newEpisodeTextWrap,
        scrub: true,
        markers: useMarkers,
        start: () => 'top center',
        end: () => 'top+=80 center',
      },
    }
  );
};

export default NewEpisodeScene;
