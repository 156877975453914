import AwardScene from './award';
import CommunityScene from './community';
import NewEpisodeScene from './newEpisode';
import ReadingScene from './reading';
import ReviewScene from './review';
import AuthorsScene from './authors';

const useMarkers = false;

const ScrollAnimations = {
  activate: () => {
    NewEpisodeScene(useMarkers);
    ReviewScene(useMarkers);
    AwardScene(useMarkers);
    ReadingScene(useMarkers);
    CommunityScene(useMarkers);
    AuthorsScene(useMarkers);
  },
};

export default ScrollAnimations;
