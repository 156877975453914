import MobileDetect from 'mobile-detect';
import StoryCardController from './HomeStoryCardController';
import FeaturedSectionAnimationController from './FeaturedSectionAnimationController';
import ScrollAnimations from './ScrollAnimations';

class HomeController {
  #storyCardController = undefined;

  #featuredSectionAnimationController = undefined;

  #storyCardPrevButton = undefined;

  #storyCardNextButton = undefined;

  #handleKeyDown(e) {
    switch (e.key) {
      case 'Escape':
        this.#featuredSectionAnimationController.toggle();
        break;
      case 'ArrowLeft':
        this.#storyCardController.pop();
        break;
      case 'ArrowRight':
        this.#storyCardController.next();
        break;
      default:
        break;
    }
  }

  #setupMobileStoreBar() {
    const md = new MobileDetect(window.navigator.userAgent);
    const mobileStoreBarLink = document.getElementById('mobileStoreBarLink');
    const os = md.os();

    if (os === 'AndroidOS') {
      mobileStoreBarLink.href =
        'https://play.google.com/store/apps/details?id=com.noahclient';
    } else {
      mobileStoreBarLink.href =
        'https://itunes.apple.com/app/radish-fiction/id1076491465?ls=1&amp;mt=8';
    }
  }

  init() {
    const mediaQuery = window.matchMedia('(prefers-reduced-motion: reduce)');
    const isDisabledAnimation = mediaQuery.matches;

    if (isDisabledAnimation) {
      document.body.classList.add('home--reduce-motion');
    }

    this.#storyCardController = new StoryCardController(isDisabledAnimation);
    this.#storyCardController.init();

    this.#storyCardPrevButton = document.getElementById('prevButton');
    this.#storyCardNextButton = document.getElementById('nextButton');

    this.#storyCardPrevButton.addEventListener('click', () => {
      this.#storyCardController.pop();
    });

    this.#storyCardNextButton.addEventListener('click', () => {
      this.#storyCardController.next();
    });

    this.#featuredSectionAnimationController = new FeaturedSectionAnimationController();
    this.#featuredSectionAnimationController.init();

    if (!isDisabledAnimation) {
      this.#featuredSectionAnimationController.start();
      document.addEventListener('keydown', this.#handleKeyDown.bind(this));

      ScrollAnimations.activate();
    }

    this.#setupMobileStoreBar();
  }
}

export default HomeController;
